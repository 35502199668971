import React from "react"
import "./ribbon.styles.scss"
const Ribbon = () => (
  <div
    className="ribbon"
    data-sal="fade"
    data-sal-delay="100"
    data-sal-easing="ease-in-out"
    data-sal-duration="300"
  ></div>
)

export default Ribbon
